/**
* @author zjc[beica1@outook.com]
* @date 2021/9/26 16:18
* @description
*   VerifyWithID.vue of WeTrade
*/

<template>
<PageWithHeader>
  <template #title>
    <t path="idproof_5">Proof of Identity</t>
  </template>
  <div class="px-16">
    <t as="p" path="idproof_2" class="mt-24">
      The proof of identity is required to withdraw cash.
      Qualified documents include tax card and passport, others are not accepted.
      The name on the document must be consistent with the beneficiary name of the withdrawal.
      Please make sure that the uploaded photos are clear and legible.
    </t>
    <Form ref="form" :schema="schema" class="pt-16" @submit="post">
      <t custom #="{t}">
        <FormItem name="idType" class="block pr-8 p-r" #="{update}">
          <div class="ml-8 mt-10 c-tip spread disabled f-400" v-if="!idType">
            <t path="idproof_6"></t>
          </div>
          <select v-model="idType" class="input" style="width: 100%" @change="update(idType)">
            <option v-for="id in ids" :key="id.type" :value="id.type">{{id.name}}</option>
          </select>
        </FormItem>
        <div class="t-center">
          <div class="preview">
            <Pic v-if="img" :src="img" style="width: 100%;height: 100%" />
            <Icon v-else name="plus" class="anchor" @click="upload" />
          </div>
          <Button icon="camera" class="upload f-bold f-lg br-sm" style="height: 32px;"
            @click.prevent="upload" :progress="progress">
            <t path="idproof_3" custom #="{td}">{{td || 'Upload'}}</t>
          </Button>
        </div>
        <FormInput name="identificationId" class="block" :placeholder="t('idproof_7')">
        </FormInput>
        <FormItem name="birthday" class="block" #="{update}">
          <span v-show="!birth" class="pl-6 c-tip f-400">{{birth || t('idproof_8')}}</span>
          <input type="date" class="input" @change="e => {
              update(e.target.value)
              birth = e.target.value
            }">
        </FormItem>
        <!--<Textarea name="address" class="block pl-6" rows="4" :placeholder="t('idproof_9')">-->
        <!--</Textarea>-->
      </t>
    </Form>
  </div>
  <template #footer>
    <Button class="block primary f-bold f-lg" @click="confirm" :progress="submitProgress">
      <t path="idproof_4" custom #="{td}">{{td || 'Submit'}}</t>
    </Button>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import { uploadID } from '@/common/jsBridge.api'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { showAlert } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import { IDType, postId, readIds } from '@/modules/out/out.api'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import FormItem from 'common/form/FormItem.vue'
import FormInput from 'common/form/inputs/FormInput.vue'
// import Textarea from 'common/form/inputs/Textarea.vue'
import { goonTask, removeTask } from 'essential/task/task.temp'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Data } from '@/types'

enum Side {
  FRONT = 1,
  BACK
}

export default defineComponent({
  name: 'VerifyWithID',
  components: { FormItem, FormInput, Form, Pic, Icon, Button, PageWithHeader },
  setup () {
    const form = shallowRef()
    const ids = shallowRef<IDType[]>([])
    const idType = shallowRef('')
    const query = useRoute().query
    const taskId = query.pendingTaskId as string
    const router = useRouter()
    const img = shallowRef(query.url as string)
    const birth = shallowRef('')
    const [commit, progress] = useRequest(uploadID)
    const [submit, submitProgress] = useRequest(postId)

    const upload = () => {
      commit({ type: Side.FRONT }).then(resp => {
        img.value = (resp as { url: string }).url
      }).catch(() => {
        progress.value = false
      })
    }

    onBeforeUnmount(() => {
      removeTask(taskId)
    })

    readIds().then(resp => {
      ids.value = resp.list
    })

    const post = (data: Data) => {
      submit(data).then(() => {
        if (taskId) {
          goonTask(taskId)
        }
        router.back()
      })
    }

    const confirm = () => {
      if (!img.value) {
        showAlert(translate('toast_14'))
      } else if (form.value) {
        form.value.submit()
      }
    }

    return {
      form,
      schema: {
        birthday: {},
        idType: {},
        identificationId: {},
        // address: {},
      },
      img,
      upload,
      progress,
      confirm,
      ids,
      idType,
      post,
      submitProgress,
      birth,
    }
  },
})
</script>

<style scoped>
.preview {
  margin: 24px auto;
  width: 215px;
  height: 128px;
  border: 1px solid var(--color-grey);
}

.anchor {
  padding: 40px 0;
  color: var(--color-grey);
  font-size: 40px;
}

.upload {
  width: 167px;
  color: var(--color-primary);
  background: var(--color-primary-thin);
}
</style>
