
import { uploadID } from '@/common/jsBridge.api'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { showAlert } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import { IDType, postId, readIds } from '@/modules/out/out.api'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import FormItem from 'common/form/FormItem.vue'
import FormInput from 'common/form/inputs/FormInput.vue'
// import Textarea from 'common/form/inputs/Textarea.vue'
import { goonTask, removeTask } from 'essential/task/task.temp'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Data } from '@/types'

enum Side {
  FRONT = 1,
  BACK
}

export default defineComponent({
  name: 'VerifyWithID',
  components: { FormItem, FormInput, Form, Pic, Icon, Button, PageWithHeader },
  setup () {
    const form = shallowRef()
    const ids = shallowRef<IDType[]>([])
    const idType = shallowRef('')
    const query = useRoute().query
    const taskId = query.pendingTaskId as string
    const router = useRouter()
    const img = shallowRef(query.url as string)
    const birth = shallowRef('')
    const [commit, progress] = useRequest(uploadID)
    const [submit, submitProgress] = useRequest(postId)

    const upload = () => {
      commit({ type: Side.FRONT }).then(resp => {
        img.value = (resp as { url: string }).url
      }).catch(() => {
        progress.value = false
      })
    }

    onBeforeUnmount(() => {
      removeTask(taskId)
    })

    readIds().then(resp => {
      ids.value = resp.list
    })

    const post = (data: Data) => {
      submit(data).then(() => {
        if (taskId) {
          goonTask(taskId)
        }
        router.back()
      })
    }

    const confirm = () => {
      if (!img.value) {
        showAlert(translate('toast_14'))
      } else if (form.value) {
        form.value.submit()
      }
    }

    return {
      form,
      schema: {
        birthday: {},
        idType: {},
        identificationId: {},
        // address: {},
      },
      img,
      upload,
      progress,
      confirm,
      ids,
      idType,
      post,
      submitProgress,
      birth,
    }
  },
})
